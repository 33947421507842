.c-prod-image__wrapper {
  max-width: 400px; //300px;
  display: block;
  margin: auto;
  position: relative;
  padding-top: 100%;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%,-50%);
  }
}
