@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

// Expand the Sizing Utility to allow for responsive breakpoints like "w-xl-50" (css, feature, v4)
// https://github.com/twbs/bootstrap/issues/21943

$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  60px: 60px,
  80px: 80px,
  100px: 100px
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

// Map-get permette di recuperare un array di elementi.
// https://jonsuh.com/blog/managing-responsive-breakpoints-with-sass-maps/
$bp_xs : map-get($grid-breakpoints, xs);
$bp_sm : map-get($grid-breakpoints, sm);
$bp_md : map-get($grid-breakpoints, md);
$bp_lg : map-get($grid-breakpoints, lg);
$bp_xl : map-get($grid-breakpoints, lx);

// Container width
// https://stackoverflow.com/questions/47473690/how-to-refer-to-container-max-widths-in-bootstrap/47475212

@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key);
}

@mixin container-responsive-width() {
  width: 100%;
  padding-right: $spacer;
  padding-left: $spacer;
  margin-right: auto;
  margin-left: auto;
  max-width: get-breakpoints("sm");
  @include media-breakpoint-up(md) {
    max-width: get-breakpoints("md");
  }
  @include media-breakpoint-up(lg) {
    max-width: get-breakpoints("lg");
  }
  @include media-breakpoint-up(xl) {
    max-width: get-breakpoints("xl");
  }
}

// ANIMATIONS

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// TRANSITIONS

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
