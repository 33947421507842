
.c-image {
  display: block;
  background: transparent center center no-repeat;
  width: 100%;
  height: 0;
  &--bkg {
    background-repeat: no-repeat;
    background-position: center;
  }
  &--bordered {
    border: #eee solid 3px;
  }
}
