// TODO: aggiorna la cartella icomoon in public e aggiungi /icomoon/ nei links quando rigeneri il font + aggiungi font-display:fallback; (testare)

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon/fonts/icomoon.eot?vv9xzd');
  src:  url('/assets/fonts/icomoon/fonts/icomoon.eot?vv9xzd#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon/fonts/icomoon.ttf?vv9xzd') format('truetype'),
    url('/assets/fonts/icomoon/fonts/icomoon.woff?vv9xzd') format('woff'),
    url('/assets/fonts/icomoon/fonts/icomoon.svg?vv9xzd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clipboard:before {
  content: "\e900";
}
.icon-banknote:before {
  content: "\e901";
}
.icon-heart:before {
  content: "\f004";
}
.icon-star:before {
  content: "\f005";
}
.icon-star-o:before {
  content: "\f006";
}
.icon-cog:before {
  content: "\f013";
}
.icon-gear:before {
  content: "\f013";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-fire:before {
  content: "\f06d";
}
.icon-chevron-up:before {
  content: "\f077";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
.icon-thumbs-o-up:before {
  content: "\f087";
}
.icon-thumbs-o-down:before {
  content: "\f088";
}
.icon-star-half:before {
  content: "\f089";
}
.icon-heart-o:before {
  content: "\f08a";
}
.icon-credit-card:before {
  content: "\f09d";
}
.icon-certificate:before {
  content: "\f0a3";
}
.icon-star-half-empty:before {
  content: "\f123";
}
.icon-star-half-full:before {
  content: "\f123";
}
.icon-star-half-o:before {
  content: "\f123";
}
.icon-unlock-alt:before {
  content: "\f13e";
}
.icon-thumbs-up:before {
  content: "\f164";
}
.icon-thumbs-down:before {
  content: "\f165";
}
.icon-paper-plane-o:before {
  content: "\f1d9";
}
.icon-send-o:before {
  content: "\f1d9";
}
.icon-cc-paypal:before {
  content: "\f1f4";
}
.icon-credit-card-alt:before {
  content: "\f283";
}
.icon-shopping-bag:before {
  content: "\f290";
}
.icon-handshake:before {
  content: "\f2b5";
}
.icon-user-circle:before {
  content: "\f2bd";
}
.icon-user-circle-o:before {
  content: "\f2be";
}
.icon-search:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
