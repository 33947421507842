/* CARD */

.card {
  max-width: 350px;
  margin-left:auto;
  margin-right:auto;
  width: 100%;
  &--no-border-top {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &--no-border-bottom {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &--full-bkg {
    background: $card-cap-bg;
  }
  .bottom-wrap {
      padding: 15px;
      border-top: $card-border-width $card-border-radius $card-border-color;
  }
}

/* BTN */

.btn {
  text-transform: uppercase !important;
  &:not(.navbar-brand) {
    font-weight: bold !important;
  }
}

// width a 33% e 66%

.w-33 { width: 33.333% !important; }
@include media-breakpoint-up(sm) { .w-sm-33 { width: 33.333% !important; } }
@include media-breakpoint-up(md) { .w-md-33 { width: 33.333% !important; } }
@include media-breakpoint-up(lg) { .w-lg-33 { width: 33.333% !important; } }
@include media-breakpoint-up(xl) { .w-xl-33 { width: 33.333% !important; } }

.w-66 { width: 66.666% !important; }
@include media-breakpoint-up(sm) { .w-sm-66 { width: 66.666% !important; } }
@include media-breakpoint-up(md) { .w-md-66 { width: 66.666% !important; } }
@include media-breakpoint-up(lg) { .w-lg-66 { width: 66.666% !important; } }
@include media-breakpoint-up(xl) { .w-xl-66 { width: 66.666% !important; } }
